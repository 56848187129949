import { faAngleDoubleRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

import { AnnouncementNode } from "../../generated/schema";
import { logAmplitudeEvent } from "../../utils/analytics";
import { ANNOUNCEMENT_EVENTS } from "../../utils/analytics/events";

import {
  AnnouncementContainer,
  BannerContainer,
  BodyText,
  ButtonContainer,
  CloseButton,
  Link,
  Paragraph,
  PrefixText,
} from "./styles";

interface AnnouncementProps {
  banner: AnnouncementNode;
}

export const AnnouncementBanner = ({ banner }: AnnouncementProps) => {
  const { slug, linkUrl, linkTitle, prefixText, bodyText, style, id } = banner;
  const cookieName = `hide-announcement-banner-${id}`;
  const [cookies, setCookie] = useCookies([cookieName]);

  useEffect(() => {
    logAmplitudeEvent(ANNOUNCEMENT_EVENTS.BANNER.VIEWED, {
      "Announcement Slug": slug,
      "Announcement Link URL": linkUrl,
    });
  }, [slug, linkUrl]);

  const onClickHandler = () => {
    setCookie(cookieName, true, {
      path: "/",
      expires: new Date(2100, 1),
    });
  };

  if (cookies[cookieName]) return null;

  return (
    <BannerContainer
      data-testid={id}
      gradientStart={style.badgeGradientStart}
      gradientEnd={style.badgeGradientEnd}
    >
      <AnnouncementContainer>
        <Paragraph>
          {prefixText && (
            <PrefixText textColor={style.badgeTextColor}>
              {prefixText}
            </PrefixText>
          )}{" "}
          {bodyText && <BodyText>{bodyText}</BodyText>}{" "}
          {linkUrl && linkTitle && (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={linkUrl}
              onClick={() =>
                logAmplitudeEvent(ANNOUNCEMENT_EVENTS.BANNER.CLICKED, {
                  "Announcement Slug": slug,
                  "Announcement Link URL": linkUrl,
                })
              }
            >
              {linkTitle}
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                size={"sm"}
                className="ml-1"
              />
            </Link>
          )}
        </Paragraph>
      </AnnouncementContainer>

      <ButtonContainer>
        <CloseButton onClick={onClickHandler} aria-label="close">
          <FontAwesomeIcon icon={faTimes} size={"sm"} />
        </CloseButton>
      </ButtonContainer>
    </BannerContainer>
  );
};
